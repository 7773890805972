import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';
import lang from '../../../langs';
import config from '../../../config';

import {
  createProductType,
  updateProductType,
  getProductType,
  getProductTypeLists,
} from '../../../actions/productType';
import { withRouter } from '../../../withRouter';

const requiredFields = ['name'];

class FormProductTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit Product Type' : 'Create Product Type',
      productType: {},
      image_url: '',
      icon: null,
    };
    this.handleSave = this.handleSave.bind(this);
    this.processData = this.processData.bind(this);
  }

  updateState(name, value) {
    this.setState({
      productType: {
        ...this.state.productType,
        [name]: value,
      },
    });
  }

  processData = async (values) => {
    delete values.icon;

    if (this.props.router.params.id) {
      await this.props.updateProductType(this.props.router.params.id, values);
    } else {
      await this.props.createProductType(values);
    }
    this.props.getProductTypeLists();

    createNotification('success', lang('success.update'));
    this.setState({ loading: false });
  };

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.productType, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      const values = { ...this.state.productType };
      values.description = window.tinymce.get('description').getContent();
      values.introduction = window.tinymce.get('introduction').getContent();
      values.image_url = this.state.image_url;
      values.icon = this.state.icon;

      this.processData(values);
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.state.productType?.description)
        window.tinymce.get('description').setContent(this.state.productType?.description);
      if (this.state.productType?.introduction)
        window.tinymce.get('introduction').setContent(this.state.productType?.introduction);
    }, 200);
  }

  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getProductType(this.props.router.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.productType !== this.props.productType) {
      this.setState({ productType: nextProps.productType.data, image_url: nextProps.productType?.data?.image_url });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <form className="row g-3 needs-validation" onSubmit={this.handleSave} novalidate="">
          <div className="col-md-12">
            <label className="form-label" htmlFor="inputFullName">
              Name
            </label>
            <input
              className="form-control"
              id="inputFullName"
              type="text"
              required="required"
              value={this.state.productType?.name}
              onChange={(e) => this.updateState('name', e.target.value)}
            />
            <div className="invalid-feedback">Please enter name</div>
          </div>
          <div className="mb-6">
            <h4 className="mb-3">Introduction</h4>
            <textarea
              className="tinymce"
              name="content"
              id="introduction"
              data-tinymce='{"height":"15rem","placeholder":"Write a intro here..."}'
            ></textarea>
          </div>
          <div className="mb-6">
            <h4 className="mb-3">Description</h4>
            <textarea
              className="tinymce"
              name="content"
              id="description"
              data-tinymce='{"height":"15rem","placeholder":"Write a default description here..."}'
            ></textarea>
          </div>
          <div className="col-md-12">
            <label className="form-label" htmlFor="inputFee">
              Fee charge seller
            </label>
            <input
              className="form-control"
              id="inputFee"
              type="text"
              value={this.state.productType?.fee_charge_seller}
              onChange={(e) => this.updateState('fee_charge_seller', e.target.value)}
            />
          </div>
          <div className="col-md-12">
            <label className="form-label" htmlFor="inputDay">
              Number of on hold days
            </label>
            <input
              className="form-control"
              id="inputDay"
              type="text"
              value={this.state.productType?.number_of_on_hold_days}
              onChange={(e) => this.updateState('number_of_on_hold_days', e.target.value)}
            />
          </div>
          <div className="col-md-12">
            <input
              type="checkbox"
              className="custom-control-input"
              id="rememberMe"
              checked={this.state.productType?.is_one_product}
              onChange={(e) => this.updateState('is_one_product', e.target.checked)}
            />
            <label className="custom-control-label ms-1" htmlFor="rememberMe">
              Single product
            </label>
          </div>
          <div className="col-md-12">
            <input
              type="checkbox"
              className="custom-control-input"
              id="basedRank"
              checked={this.state.productType?.is_based_rank}
              onChange={(e) => this.updateState('is_based_rank', e.target.checked)}
            />
            <label className="custom-control-label ms-1" htmlFor="basedRank">
              Validate Order Amount
            </label>
          </div>
          <div className="mb-6">
            <h4 className="mb-3">Meta title</h4>
            <input
              className="form-control"
              type="text"
              value={this.state.productType?.meta_title}
              onChange={(e) => {
                this.updateState('meta_title', e.target.value);
              }}
            />
          </div>
          <div className="mb-6">
            <h4 className="mb-3">Meta Description</h4>
            <input
              className="form-control"
              type="text"
              value={this.state.productType?.meta_description}
              onChange={(e) => {
                this.updateState('meta_description', e.target.value);
              }}
            />
          </div>
          <div className="col-12">
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormProductTypes.propTypes = {
  auth: PropTypes.object.isRequired,
  productType: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createProductType: PropTypes.func.isRequired,
  updateProductType: PropTypes.func.isRequired,
  getProductType: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  productType: state.productTypes.productType,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  createProductType,
  updateProductType,
  getProductType,
  getProductTypeLists,
})(withRouter(FormProductTypes));
