import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Countdown from 'react-countdown';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { withRouter } from '../../../withRouter';
import { createNotification } from '../../../utils/helpers';
import lang from '../../../langs';
import config from '../../../config';
import { formatDateTime, convertCurrency, imgModal, pasteIntoInput, escapeHTML } from '../../../utils/helpers';
import { sendNotification } from '../../../utils/notificationHelper';
import {
  TRANSACTION_STATUS_PARTIAL_REFUND,
  TRANSACTION_STATUS_BUYER_NOT_RECEIVE,
  TRANSACTION_STATUS_ON_HOLD,
  TRANSACTION_STATUS_COMPLETED,
  TRANSACTION_STATUS_BUYER_RECEIVE,
  TRANSACTION_STATUS_REFUND,
} from '../../../utils/constants';
import { getTransactionsDetail, updateTransaction } from '../../../actions/transaction';
import {
  getSupportReplies,
  createResolve,
  replySupport,
  updateSupport,
  getResolveDetail,
  getSupportDetail,
} from '../../../actions/ticket';

const OrderStatuses = {
  [TRANSACTION_STATUS_REFUND]: 'Canceled',
  [TRANSACTION_STATUS_ON_HOLD]: 'Delivered',
  [TRANSACTION_STATUS_BUYER_NOT_RECEIVE]: 'Suspended',
  [TRANSACTION_STATUS_BUYER_RECEIVE]: 'On Hold',
  [TRANSACTION_STATUS_PARTIAL_REFUND]: 'Partial Refund',
};
const RenderOrderStatuses = [
  'UnKnown',
  'Pending',
  'Awaiting Payment',
  'Awaiting Delivery',
  'Transaction Completed',
  'Canceled',
  'Fail',
  'Delivered',
  'Suspended',
  'Transaction Completed',
  'Transaction Completed',
];

const requiredFields = ['refund', 'disbursement'];

class SupportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 0,
      lastReply: {},
      inputValue: '',
      imageUrl: '',
      replies: [],
      transaction: {},
      dispute: {},
      loading: false,
      order: {},
    };
    this.handleTransactionStatus = this.handleTransactionStatus.bind(this);
    this.handleUpdatePriority = this.handleUpdatePriority.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleUpdateOrderStatus = this.handleUpdateOrderStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }
  uploadImage = async (file) => {
    const body = new FormData();
    body.append('file', file);
    body.append('path', `/support/`);
    body.append('type_upload', 'users');
    body.append('name', 'admin_chat');
    const me = this;

    fetch(`${config.url}/assets/v1/auth/admin/asset/upload`, {
      method: 'POST',
      body,
      headers: { 'access-token': sessionStorage.getItem(config.tokenKey) },
    })
      .then((response) => response.json())
      // .then((json) => me.setState({ imageUrl: json?.data?.img_urls[0] }))
      .then((json) => me.setState({ imageUrl: json?.data?.img_urls[0] }))
      .catch((err) => console.log(err));
  };
  handleImageUpload = () => {
    var fileTypes = ['jpg', 'jpeg', 'png', 'gif'];
    const input = document.getElementById('supportChatPhotos');

    const file = input.files[0];
    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;
    const me = this;
    var extension = file.name.split('.').pop().toLowerCase();

    if (file && fileMb <= 10 && fileTypes.indexOf(extension) >= 0) {
      const reader = new FileReader();

      reader.onload = function (e) {
        me.uploadImage(file);
      };

      reader.readAsArrayBuffer(file);
    }
  };
  handleInput = (e) => {
    e.preventDefault();
    e.target.style.height = 'auto';
    let height = e.target.scrollHeight < 300 ? e.target.scrollHeight : 300;
    e.target.style.height = height + 'px';
  };
  handleKeyUp = (e) => {
    if (e.keyCode == 13 && (e.shiftKey || e.altKey)) {
      if (e.type == 'keypress') {
        pasteIntoInput(this, '\n');
      }
    } else if (e.keyCode === 13) {
      const message = e.target.value;
      const validateMessage = (message || '').replaceAll('\n', '');
      this.setState({
        inputValue: validateMessage,
      });
      this.handleSubmit();
    }
  };
  handleSubmit = (e) => {
    if (e) e.preventDefault();
    this.props.replySupport({
      subject: this.state.inputValue,
      replied_to: this.props.router.params.id,
      description: this.state.inputValue,
      attachments: this.state.imageUrl,
      status: 'Open',
      priority: 'Low',
    });
    // this.props.getSupportReplies(this.props.router.params.id);
    const replies = this.state.replies?.data || [];
    replies.push({
      subject: this.state.inputValue,
      replied_to: this.props.router.params.id,
      description: this.state.inputValue,
      attachments: this.state.imageUrl,
      status: 'Open',
      priority: 'Low',
      created_time: new Date().getTime() / 1000,
    });
    this.setState({
      inputValue: '',
      imageUrl: '',
      replies: {
        data: replies,
      },
    });
  };

  handleUpdateOrderStatus = async (e) => {
    await this.handleUpdateStatus('Close', e.target.value);
    await this.props.updateTransaction(this.state.transaction?.data?.tx_id, {
      status: e.target.value * 1,
    });
    await this.props.getTransactionsDetail(this.state.transaction?.data?._id);
    if (e.target.value * 1 !== TRANSACTION_STATUS_PARTIAL_REFUND) {
      sendNotification(
        this.state.transaction?.data?.seller_id,
        JSON.stringify({
          ...this.state.transaction?.data,
          status: e.target.value * 1,
        }),
      );
      sendNotification(
        this.state.transaction?.data?.buyer_id,
        JSON.stringify({
          ...this.state.transaction?.data,
          status: e.target.value * 1,
        }),
      );
    }
  };
  handleOrderStatus = async (e) => {
    await this.props.updateTransaction(this.state.transaction?.data?.tx_id, {
      status: e.target.value * 1,
    });
    await this.props.getTransactionsDetail(this.state.transaction?.data?._id);
    if (e.target.value * 1 !== TRANSACTION_STATUS_PARTIAL_REFUND) {
      sendNotification(
        this.state.transaction?.data?.seller_id,
        JSON.stringify({
          ...this.state.transaction?.data,
          status: e.target.value * 1,
        }),
      );
      sendNotification(
        this.state.transaction?.data?.buyer_id,
        JSON.stringify({
          ...this.state.transaction?.data,
          status: e.target.value * 1,
        }),
      );
    }
  };
  handleUpdateStatus = async (status, order_status) => {
    const newItem = {
      priority: this.props.tickets.support.priority,
      status,
    };
    if (order_status) {
      newItem['object_status'] = order_status;
    }
    await this.props.updateSupport(this.props.tickets.support._id, newItem);
    await this.props.getSupportDetail(this.props.tickets.support._id);
  };
  handleUpdatePriority = (e) => {
    const newItem = {
      ...this.props.tickets.support,
      priority: e.target.value,
    };
    this.props.updateSupport(newItem._id, newItem);
    this.props.getSupportDetail(newItem._id);
  };
  handleTransactionStatus = (status) => {
    let stepActive = 2;
    if (status === TRANSACTION_STATUS_ON_HOLD) stepActive = 3;
    else if (
      status === TRANSACTION_STATUS_COMPLETED ||
      status === TRANSACTION_STATUS_BUYER_RECEIVE ||
      status === TRANSACTION_STATUS_PARTIAL_REFUND
    ) {
      stepActive = 4;
    }

    const stepTitle =
      status == TRANSACTION_STATUS_REFUND
        ? 'Canceled'
        : status == TRANSACTION_STATUS_BUYER_NOT_RECEIVE
        ? 'Suspended'
        : 'Delivered';
    let steps = ['', 'Order Created', 'Awaiting Delivery', stepTitle, 'Transaction Completed'];

    return steps[stepActive];
  };
  setOrder(order) {
    this.setState({
      order,
    });
  }
  updateState(name, value) {
    this.setState({
      dispute: {
        ...this.state.dispute,
        [name]: value,
      },
    });
  }
  handleOk = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.dispute, requiredFields);
      } catch (err) {
        this.setState({ loading: false });
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      const values = this.state.dispute;
      values.ticketid = this.props.tickets?.support?._id;
      if (values.refund * 1 + values.disbursement * 1 != this.state.transaction?.data?.price) {
        createNotification('error', 'The sum of Refund and disbursement has to be equal to Total');
        this.setState({ loading: false });
      } else {
        this.props.createResolve(values);
        this.setState({
          dispute: {},
          loading: false,
        });
        createNotification('success', lang('success.update'));
      }
    }
  };
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.getSupportDetail(this.props.router.params.id);
      this.props.getSupportReplies(this.props.router.params.id);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.router.params.id != nextProps.router.params.id) {
      this.props.getSupportDetail(nextProps.router.params.id);
      this.props.getSupportReplies(nextProps.router.params.id);
    }
    if (nextProps.tickets.support !== this.props.tickets.support) {
      if (nextProps.tickets.support?.object_id && nextProps.tickets.support?.ticket_type == 'Dispute') {
        this.props.getTransactionsDetail(nextProps.tickets.support?.object_id);
        if (Object.keys(nextProps.tickets.resolve).length == 0)
          this.props.getResolveDetail(nextProps.tickets.support?._id);
      }
    }
    if (nextProps.tickets.replies !== this.props.tickets.replies) {
      this.setState({
        replies: nextProps.tickets.replies,
      });
      const lastUpdated =
        nextProps.tickets.replies?.data?.length > 0
          ? nextProps.tickets.replies?.data[(nextProps.tickets.replies?.data || []).length - 1]
          : nextProps.tickets.support;

      const findLastResponder =
        nextProps.tickets.replies?.data.filter(
          (el) =>
            el.reply_type === 'normal' &&
            (el.replied_by === this.state.transaction?.data?.buyer_id ||
              el.replied_by === this.state.transaction?.data?.seller_id),
        ) || [];
      const lastReplyItem =
        findLastResponder?.length > 0 ? findLastResponder[findLastResponder.length - 1] : nextProps.tickets.support;
      this.setState({
        timer: nextProps.tickets.support?.created_time,
        lastReply: lastReplyItem,
      });
    }
    if (nextProps.transactions !== this.props.transactions) {
      this.setState({
        transaction: nextProps.transactions.transaction,
      });
    }
  }
  render() {
    return (
      <div class="container">
        <div class="row mb-3">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <Link to={-1} className="btn btn-link text-body me-4 px-0">
                <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
              </Link>
            </div>
          </div>
          <div class={`col-md-6 p-2`}>
            <div class="card shadow-none border mb-3 p-4">
              <h4>{this.props.tickets.support?.subject}</h4>
              <hr />
              <div class="row">
                <div class="col">
                  <h6>Requested By:</h6>
                  <div className="pb-2">
                    <span>
                      {this.props.tickets.support?.user_info?.user_name || this.props.tickets.support?.requested_by}
                    </span>
                    <span className="pl-2">
                      ({this.props.tickets.support?.requested_by || this.props.tickets.support?.replied_by})
                    </span>
                  </div>
                  <h6>Ticket ID:</h6>
                  <div className="pb-2">
                    <span>{this.props.tickets.support?._id}</span>
                  </div>
                  {this.state.transaction?.data && (
                    <div className="pb-2">
                      <h6>Transaction ID:</h6>
                      <span>{this.state.transaction?.data?.merchant_trade_no}</span>
                    </div>
                  )}
                  <div>
                    <h6>
                      {this.props.tickets.support?.ticket_type == 'Dispute'
                        ? 'Dispute Initiation Date'
                        : 'Created Date'}
                    </h6>
                    <span>{formatDateTime(this.props.tickets.support?.created_time * 1000)}</span>
                  </div>
                </div>
                <div class="col">
                  {this.props.tickets.support?.ticket_type !== 'Dispute' && (
                    <div class="pb-2">
                      <h6>Status</h6>
                      <select
                        class="form-select1"
                        data-choices="data-choices"
                        data-options='{"removeItemButton":false,"placeholder":true}'
                        style={{ width: '100px' }}
                        onChange={(e) => this.handleUpdateStatus(e.target.value)}
                      >
                        {['Close', 'Open'].map((el) => (
                          <option selected={this.props.tickets.support?.status == el} value={el}>
                            {el}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {this.state.transaction?.data &&
                    this.props.tickets.support?.ticket_type === 'Dispute' &&
                    this.props.tickets.support?.object_id &&
                    this.state.transaction?.data?._id === this.props.tickets.support?.object_id && (
                      <div class="pb-2">
                        <h6>Resolve Status:</h6>
                        <select
                          class="form-select1"
                          data-choices="data-choices"
                          data-options='{"removeItemButton":false,"placeholder":true}'
                          style={{ width: '100px' }}
                          onChange={this.handleUpdateOrderStatus}
                        >
                          <option value="">Select status</option>
                          {Object.keys(OrderStatuses).map((el) => (
                            <option value={el} selected={this.props.tickets.support?.object_status == el}>
                              {OrderStatuses[el]}
                            </option>
                          ))}
                        </select>
                        {/* <h6>Order Status:</h6>
                        <select
                          class="form-select1"
                          data-choices="data-choices"
                          data-options='{"removeItemButton":false,"placeholder":true}'
                          style={{ width: '100px' }}
                          onChange={this.handleOrderStatus}
                        >
                          <option value="">Select status</option>
                          {Object.keys(OrderStatuses).map((el) => (
                            <option value={el} selected={this.props.tickets.support?.object_status == el}>
                              {OrderStatuses[el]}
                            </option>
                          ))}
                        </select> */}
                      </div>
                    )}
                  {this.props.tickets.support?.status == 'Close' && (
                    <>
                      <div class="pb-2">
                        <h6>Dispute closing time:</h6>
                        <span>{formatDateTime(this.props.tickets.support?.updated_time * 1000)}</span>
                      </div>
                      {this.props.tickets.support?.object_status && (
                        <div class="pb-2">
                          <h6>Final decision maker:</h6>
                          <span>
                            {this.props.tickets.support?.updated_by == '55489c06-557a-40f0-ad0e-e910bf8b017c'
                              ? 'The PlaySwap Final Resolution Committee'
                              : this.props.tickets.support?.closer_info.user_name}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <hr />
              <div>
                {this.state.timer > 0 && this.props.tickets.support?.status !== 'Close' && (
                  <>
                    <p>
                      <span>The Ticket will be closed in </span>
                      <span className="text-danger">
                        <Countdown date={this.state.timer * 1000 + 48 * 3600000} />
                      </span>
                      <span> if there is no response.</span>
                    </p>
                    <hr />
                  </>
                )}
                {this.props.tickets.resolve?.data &&
                this.props.tickets.resolve?.data?.ticketid === this.props.tickets.support?._id ? (
                  <div className="row">
                    <div className="pb-2 py-1">
                      <h4>Dispute Outcome</h4>
                    </div>
                    <div className="fw-bold col-6 py-1">Refund to the Buyer: </div>
                    <div
                      className="text-end col-6 py-1"
                      dangerouslySetInnerHTML={{
                        __html: convertCurrency(
                          this.props.tickets.resolve?.data?.refund,
                          true,
                          this.props.settings?.data?.sgd,
                        ),
                      }}
                    />
                    <div className="fw-bold col-6 py-1">Disbursement to the Seller: </div>
                    <div
                      className="text-end col-6 py-1"
                      dangerouslySetInnerHTML={{
                        __html: convertCurrency(
                          this.props.tickets.resolve?.data?.disbursement,
                          true,
                          this.props.settings?.data?.sgd,
                        ),
                      }}
                    />
                    <div className="fw-bold col-6 py-1">Decision time: </div>
                    <div className="text-end col-6 py-1">
                      {formatDateTime((this.props.tickets.resolve?.data?.created_time || 1) * 1000)}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div class="col-6 col-md-6 p-2">
            <div class="card shadow-none border mb-3 p-4">
              {this.props.tickets.support?.ticket_type === 'Dispute' && (
                <>
                  <h4>Order Detail</h4>
                  <hr />
                  <div className="row">
                    <div className="fw-bold col-5 border-bottom py-1">Order Title: </div>
                    <div className="text-end pb-2 col-7 border-bottom py-1">
                      {this.state.transaction?.data?.item_name} <br />
                      <a href={`/admin/marketplace/chat/${this.state.transaction?.data?._id}`}>View Chat</a>
                      {this.state.transaction?.data?.images && (
                        <a
                          href="#!"
                          className="ms-3"
                          data-bs-toggle="modal"
                          data-bs-target="#scrollingLong"
                          onClick={() => this.setOrder(this.state.transaction?.data)}
                        >
                          View Proof
                        </a>
                      )}
                    </div>
                    <div className="fw-bold col-5 border-bottom py-1">Order ID: </div>
                    <div className="text-end pb-2 col-7 border-bottom py-1">{this.state.transaction?.data?._id}</div>
                    <div className="fw-bold col-5 border-bottom py-1">Buyer Name: </div>
                    <div className="text-end pb-2 col-7 border-bottom py-1">
                      {this.state.transaction?.data?.buyer_info?.user_name}
                    </div>
                    <div className="fw-bold col-5 border-bottom py-1">Seller Name: </div>
                    <div className="text-end pb-2 col-7 border-bottom py-1">
                      {this.state.transaction?.data?.seller_info?.user_name}
                    </div>
                    <div className="fw-bold col-5 border-bottom py-1">Order Status: </div>
                    <div className="text-end pb-2 col-7 border-bottom py-1">
                      {RenderOrderStatuses[this.state.transaction?.data?.status]}
                    </div>
                    <div className="fw-bold col-5 border-bottom py-1">Placed on: </div>
                    <div className="text-end pb-2 col-7 border-bottom py-1">
                      {formatDateTime((this.state.transaction?.data?.created_time || 1) * 1000)}
                    </div>
                    <div className="fw-bold col-5 border-bottom py-1">Quantity: </div>
                    <div className="text-end pb-2 col-7 border-bottom py-1">{this.state.transaction?.data?.amount}</div>
                    <div className="fw-bold col-5 border-bottom py-1">Amount: </div>
                    <div
                      className="text-end pb-2 col-7 border-bottom py-1"
                      dangerouslySetInnerHTML={{
                        __html: convertCurrency(
                          this.state.transaction?.data?.price,
                          true,
                          this.props.settings?.data?.sgd,
                        ),
                      }}
                    />
                  </div>
                </>
              )}
              <div className="space-y-6">
                <div className="row">
                  <div className="fw-bold col-5 border-bottom py-1">Reason: </div>
                  <div className="text-end pb-2 col-7 border-bottom py-1">
                    <div>{this.props.tickets?.support?.subject}</div>
                  </div>

                  <div className="">
                    <div className="fw-bold py-1">Overview: </div>
                    <div
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: escapeHTML(this.props.tickets?.support?.description),
                      }}
                    />
                    {this.props.tickets?.support?.attachments && (
                      <div className="text-right mt-2">
                        {this.props.tickets?.support?.attachments && (
                          <img
                            aria-hidden="true"
                            onClick={() => imgModal(this.props.tickets?.support?.attachments)}
                            src={this.props.tickets?.support?.attachments}
                            width="50"
                            alt="image"
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {this.props.transactions.transaction &&
                    this.props.tickets?.support?.ticket_type === 'Dispute' &&
                    this.props.tickets?.support?.object_id &&
                    this.props.transactions.transaction?.data?._id === this.props.tickets?.support?.object_id && (
                      <>
                        {(this.state.replies?.data?.filter((i) => i.reply_type === 'request') || []).length > 0 && (
                          <>
                            <div className="col-span-2">
                              <h2 className="text-2xl mt-6">Response Detail</h2>
                            </div>
                            {(this.state.replies?.data?.filter((i) => i.reply_type === 'request') || []).map((el) => {
                              return (
                                <>
                                  <div className="border-b border-opacity-10 pb-2">
                                    {`${
                                      el.replied_by === this.props.transactions.transaction?.data?.seller_id
                                        ? 'The Seller'
                                        : 'The Buyer'
                                    } ${
                                      el.replied_by === this.props.transactions.transaction?.data?.seller_id
                                        ? 'wish to refund'
                                        : 'wants a refund of'
                                    }`}
                                    :{' '}
                                  </div>
                                  <div
                                    className="text-[#F8A023] text-right border-b border-opacity-10 pb-2"
                                    dangerouslySetInnerHTML={{
                                      __html: convertCurrency(el.subject, true, this.props.settings?.data?.sgd),
                                    }}
                                  />
                                  <div className="border-b border-opacity-10 pb-2">Return the account: </div>
                                  <div className="text-right border-b border-opacity-10 pb-2">{el.description}</div>
                                </>
                              );
                            })}
                          </>
                        )}
                        {this.props.tickets?.resolve?.data &&
                        this.props.tickets?.resolve?.data?.ticketid === this.props.tickets?.support?._id ? (
                          <></>
                        ) : (
                          this.props.transactions.transaction?.data?.status === 10 && (
                            <div className="col-span-2 pt-4">
                              <button
                                type="button"
                                className="btn btn-primary"
                                aria-hidden="true"
                                data-bs-toggle="modal"
                                data-bs-target="#disputeModal"
                              >
                                <div>Dispute Outcome</div>
                              </button>
                            </div>
                          )
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 p-2">
            <div class="card shadow-none border mb-3 p-4">
              <h4 className="pb-2">Conversation</h4>
              {this.state.replies?.data &&
                this.state.replies?.data
                  ?.filter((i) => i.reply_type !== 'request')
                  .map((item) => {
                    return (
                      <div className="py-2" key={item._id}>
                        <div className="pb-1 row">
                          <div className="col d-flex align-items-center">
                            <div className="avatar avatar-m">
                              <img
                                className="rounded-circle"
                                src={
                                  item?.user_info?.user_avatar ||
                                  'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'
                                }
                                alt=""
                              />
                            </div>
                            <h5 className="px-2">{item?.user_info?.user_name || 'Playswap'}</h5>
                          </div>
                          <div className="col text-end">
                            {moment(new Date(item.created_time * 1000).getTime()).fromNow()}
                          </div>
                        </div>
                        <div>
                          {item.attachments && (
                            <img
                              aria-hidden="true"
                              onClick={() => imgModal(item.attachments)}
                              alt=""
                              src={item.attachments}
                              width="100"
                            />
                          )}
                          <div
                            className="text-[16px] text-theme-gray dark:text-white60"
                            dangerouslySetInnerHTML={{
                              __html: escapeHTML(item.description).replaceAll('\n', '<br/>'),
                            }}
                          />
                        </div>
                        <hr />
                      </div>
                    );
                  })}
              <div>
                <div class="card-footer d-flex align-items-center gap-2 ps-3 pe-4 py-3">
                  <div class="d-flex align-items-center flex-1 gap-3 border border-translucent rounded-pill px-4">
                    <textarea
                      id="chatText"
                      class="form-control outline-none border-0 flex-1 fs-9 px-0"
                      placeholder="Write message"
                      style={{ height: '30px' }}
                      value={this.state.inputValue}
                      onKeyUp={(e) => {
                        this.handleKeyUp(e);
                        this.handleInput(e);
                      }}
                      onChange={(e) => {
                        this.setState({
                          inputValue: e.target.value,
                        });
                      }}
                    />
                    <label
                      class="btn btn-link d-flex p-0 text-body-quaternary fs-9 border-0"
                      htmlFor="supportChatPhotos"
                    >
                      <span class="fa-solid fa-image"></span>
                    </label>
                    <input
                      class="d-none"
                      type="file"
                      accept="image/*"
                      id="supportChatPhotos"
                      onChange={this.handleImageUpload}
                    />
                  </div>
                  <button class="btn p-0 border-0 send-btn" onClick={this.handleSubmit}>
                    <span class="fa-solid fa-paper-plane fs-9"></span>
                  </button>
                </div>
                <div class="ps-3 pe-4">{this.state.imageUrl}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="disputeModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <form class="row g-3 needs-validation" novalidate="">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Dispute Outcome
                  </h5>
                  <button class="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                    <span class="fas fa-times fs-9"></span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="floatingDisbursement"
                      required
                      placeholder="Input Disbursement to the Seller"
                      value={this.state.dispute?.disbursement}
                      onChange={(e) => this.updateState('disbursement', e.target.value)}
                    />
                    <label htmlFor="floatingDisbursement">Disbursement to the Seller</label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="floatingRefund"
                      required
                      placeholder="Input Refund Amount to the Buyer"
                      value={this.state.dispute?.refund}
                      onChange={(e) => this.updateState('refund', e.target.value)}
                    />
                    <label htmlFor="floatingRefund">Refund Amount to the Buyer</label>
                  </div>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-primary" type="button" disabled={this.state.loading} onClick={this.handleOk}>
                    Save
                  </button>
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    data-bs-dismiss="modal"
                    onClick={() =>
                      this.setState({
                        dispute: {},
                        loading: true,
                      })
                    }
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="scrollingLong"
          tabIndex="-1"
          aria-labelledby="scrollingLongModalLabel"
          aria-hidden="true"
        >
          {this.state.order && (
            <div className="modal-dialog" style={{ maxWidth: '70%' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="scrollingLongModalLabel">
                    {this.state.order?._id}
                  </h5>
                  <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                    <span className="fas fa-times fs-9"></span>
                  </button>
                </div>
                <div className="modal-body">
                  {(this.state.order?.images || []).length > 0 &&
                    (this.state.order?.images || []).map((el, index) => (
                      <video
                        key={index}
                        controls
                        autoPlay={true}
                        poster={el}
                        style={{
                          width: '100%',
                          backgroundImage: `url(${el})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          maxHeight: '500px',
                        }}
                      >
                        <source src={el} type="video/mp4" />
                      </video>
                    ))}
                </div>
                <div className="modal-footer">
                  <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SupportDetail.propTypes = {
  auth: PropTypes.object.isRequired,
  getSupportReplies: PropTypes.func.isRequired,
  getTransactionsDetail: PropTypes.func.isRequired,
  createResolve: PropTypes.func.isRequired,
  replySupport: PropTypes.func.isRequired,
  updateSupport: PropTypes.func.isRequired,
  getResolveDetail: PropTypes.func.isRequired,
  updateTransaction: PropTypes.func.isRequired,
  getSupportDetail: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  tickets: state.tickets,
  transactions: state.transactions,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getTransactionsDetail,
  getSupportReplies,
  createResolve,
  replySupport,
  updateSupport,
  getResolveDetail,
  updateTransaction,
  getSupportDetail,
})(withRouter(SupportDetail));
