import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// routes
import Dashboard from '../dashboard';
import Users from '../settings/Users';
import UserForm from '../settings/Users/create';
import SellerRanks from '../settings/SellerRanks';
import SellerRankForm from '../settings/SellerRanks/create';
import PayoutMethods from '../settings/PayoutMethods';
import PayoutMethodForm from '../settings/PayoutMethods/create';
import PaymentMethods from '../settings/PaymentMethods';
import PaymentMethodForm from '../settings/PaymentMethods/create';
import PaymentSettings from '../settings/Settings';

//games routes
import Games from '../games';
import FormGames from '../games/create';
import GameTypes from '../games/gameTypes';
import FormGameTypes from '../games/gameTypes/create';
import Products from '../games/products';
import ProductTypes from '../games/productTypes';
import FormProductTypes from '../games/productTypes/create';

// marketplace
import MarketPlaceUsers from '../marketplace/users';
import MarketPlaceUsersForm from '../marketplace/users/create';
import MarketPlaceTransactions from '../marketplace/transactions';
import MarketPlaceRefund from '../marketplace/refund';
import MarketPlaceRefundView from '../marketplace/refund/view';
import MarketPlacePayoutReport from '../marketplace/payout-report';
import MarketPlaceReviews from '../marketplace/reviews';
import FormReviews from '../marketplace/reviews/create';
import ChatScreen from '../marketplace/chat';
import SubscribersView from '../marketplace/subscribers';

import AffiliateTransactions from '../affiliate/transactions';
import AffiliatePayouts from '../affiliate/payouts';
import MarketingBanner from '../marketing/banner';
import MarketingBannerForm from '../marketing/banner/create';

// support
import SupportDispute from '../support/dispute';
import SupportDetail from '../support/view';
import SupportForm from '../support/dispute/new';
import SupportChat from '../support/chat';

import Accounting from '../accounting';
import AccountingExpense from '../accounting/expense';
import AccountingExpenseForm from '../accounting/expense/create';
import AccountingExpenseCategory from '../accounting/expenseCategory';
import AccountingExpenseCategoryForm from '../accounting/expenseCategory/create';

import Navbar from '../layout/Navbar';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import { USER_TYPE_ENUM } from '../../utils/constants';

const AdminRoute = ({ auth }) => (
  <>
    {auth.isAuthenticated === true ? (
      <main className="main" id="top">
        <Navbar />
        <Header />
        <div className="content">
          <Routes>
            <Route index element={<Dashboard />} />
            <Route path="/settings/members" element={<Users type={USER_TYPE_ENUM.ADMIN} role="Admin" />} />
            <Route path="/settings/users" element={<Users status={USER_TYPE_ENUM.USER} role="User" />} />
            <Route
              path="/settings/users/inactive"
              element={<Users status={USER_TYPE_ENUM.INACTIVE} role="Inactive User" />}
            />
            <Route path="/settings/users/block" element={<Users status={2} role="Blocked User" />} />
            <Route path="/settings/users/add" element={<UserForm />} />
            <Route path="/settings/users/edit/:id" element={<UserForm />} />
            <Route path="/settings/seller-ranks" element={<SellerRanks />} />
            <Route path="/settings/seller-ranks/add" element={<SellerRankForm />} />
            <Route path="/settings/seller-ranks/edit/:id" element={<SellerRankForm />} />
            <Route path="/settings/payout-methods" element={<PayoutMethods />} />
            <Route path="/settings/payout-methods/add" element={<PayoutMethodForm />} />
            <Route path="/settings/payout-methods/edit/:id" element={<PayoutMethodForm />} />
            <Route path="/settings/payment-methods" element={<PaymentMethods />} />
            <Route path="/settings/payment-methods/add" element={<PaymentMethodForm />} />
            <Route path="/settings/payment-methods/edit/:id" element={<PaymentMethodForm />} />
            <Route path="/settings/payment" element={<PaymentSettings />} />
            <Route path="/games" element={<Games />} />
            <Route path="/games/add" element={<FormGames />} />
            <Route path="/games/edit/:id" element={<FormGames />} />
            <Route path="/games/types" element={<GameTypes />} />
            <Route path="/games/types/add" element={<FormGameTypes />} />
            <Route path="/games/types/edit/:id" element={<FormGameTypes />} />
            <Route path="/games/products" element={<Products />} />
            <Route path="/games/products/add" element={<Products />} />
            <Route path="/games/products/edit/:id" element={<Products />} />
            <Route path="/games/product-types" element={<ProductTypes />} />
            <Route path="/games/product-types/add" element={<FormProductTypes />} />
            <Route path="/games/product-types/edit/:id" element={<FormProductTypes />} />

            <Route path="/marketplace/users" element={<MarketPlaceUsers type={2} />} />
            <Route path="/marketplace/users/inactive" element={<MarketPlaceUsers type={3} />} />
            <Route path="/marketplace/users/edit/:id" element={<MarketPlaceUsersForm />} />
            <Route path="/marketplace/transactions" element={<MarketPlaceTransactions />} />
            <Route path="/marketplace/refund" element={<MarketPlaceRefund />} />
            <Route path="/marketplace/refund/:id" element={<MarketPlaceRefundView />} />
            <Route path="/marketplace/payout-report" element={<MarketPlacePayoutReport />} />
            <Route path="/marketplace/reviews" element={<MarketPlaceReviews />} />
            <Route path="/marketplace/reviews/add" element={<FormReviews />} />
            <Route path="/marketplace/reviews/edit/:id" element={<FormReviews />} />
            <Route path="/marketplace/chat/:id" element={<ChatScreen />} />
            <Route path="/marketplace/subscribers" element={<SubscribersView />} />

            <Route path="/affiliate/transactions" element={<AffiliateTransactions />} />
            <Route path="/affiliate/payouts" element={<AffiliatePayouts />} />
            <Route path="/marketing/banners" element={<MarketingBanner />} />
            <Route path="/marketing/banners/add" element={<MarketingBannerForm />} />

            <Route path="/support/disputes" element={<SupportDispute type="Dispute" title="Dispute" />} />
            <Route path="/support/tickets" element={<SupportDispute type="Other" title="Support" />} />
            <Route path="/support/view/:id" element={<SupportDetail />} />
            <Route path="/support/add" element={<SupportForm />} />
            <Route path="/support/kyc" element={<MarketPlaceUsers type={0} />} />
            <Route path="/support/chat" element={<SupportChat />} />

            <Route path="/accounting" element={<Accounting />} />
            <Route path="/accounting/expense" element={<AccountingExpense />} />
            <Route path="/accounting/expense/add" element={<AccountingExpenseForm />} />
            <Route path="/accounting/expense/edit/:id" element={<AccountingExpenseForm />} />
            <Route path="/accounting/expense-categories" element={<AccountingExpenseCategory />} />
            <Route path="/accounting/expense-categories/add" element={<AccountingExpenseCategoryForm />} />
            <Route path="/accounting/expense-categories/edit/:id" element={<AccountingExpenseCategoryForm />} />
          </Routes>
          <Footer />
        </div>
      </main>
    ) : (
      <Navigate to="/" />
    )}
  </>
);

AdminRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminRoute);
