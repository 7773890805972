import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import { CREATE_MARKETING_BANNER, DELETE_MARKETING_BANNER, GET_ERRORS, GET_MARKETING_BANNER_LIST } from './types';

export const getMarketingBannerLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/affiliate/v1/auth/admin/marketing-banner?${params}`)
      .then((res) => {
        dispatch({
          type: GET_MARKETING_BANNER_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deleteMarketingBanner = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/affiliate/v1/auth/admin/marketing-banner/${id}`, {})
      .then((res) => {
        dispatch({
          type: DELETE_MARKETING_BANNER,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createMarketingBanner = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/affiliate/v1/auth/admin/marketing-banner`, values)
      .then((res) => {
        dispatch({
          type: CREATE_MARKETING_BANNER,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
