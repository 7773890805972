import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { DebounceInput } from 'react-debounce-input';

import { blockUser } from '../../../actions/user';
import { getSellerLists, unapproveSeller, approveSeller } from '../../../actions/seller';
import { DEFAULT_PAGE_SIZE, KYC_STATUS_ENUM, SOCIAL_TYPE } from '../../../utils/constants';
import { formatDateTime, createNotification, getUserId } from '../../../utils/helpers';
import SuspendModal from '../../settings/Users/Suspend';

const KYC_STATUSES = ['warning', 'danger', 'success', 'danger'];

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        field_name: 'kyc_status',
        field_value: this.props.type,
        page: 1,
        page_size: 20,
      },
      userList: [],
      total_items: 0,
      seller: {},
      loading: false,
    };
    this.setSelectdSeller = this.setSelectdSeller.bind(this);
  }
  setSelectdSeller = (seller) => {
    this.setState({ seller });
  };
  handleDisable = async (values, status) => {
    const confirm = window.confirm(`Are you sure ${KYC_STATUS_ENUM[status]} this user?`);
    if (confirm) {
      const updateUser = values;
      updateUser.status = status;
      if (status == 2) {
        await this.props.approveSeller(updateUser.id);
      } else {
        await this.props.unapproveSeller(updateUser.id);
      }
      this.paginationHandler({ selected: this.state.filter.page - 1 });
    }
    return false;
  };
  handleBlock = async (values) => {
    const confirm = window.confirm(`Are you sure to block this user?`);
    if (confirm) {
      const updateUser = values;
      await this.props.blockUser(updateUser.user_id, this.state.suspend_day * 1);
      this.setState({ user: null });
      document.querySelector('#modalClose').click();
    }
    return false;
  };
  handleOk = (suspend_day) => {
    if (suspend_day >= 0) {
      this.handleBlock(this.state.user);
    } else {
      createNotification('error', 'Suspend Day needs to more than 0.');
    }
  };
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getSellerLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  searchUser = (keyword) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          field_name: 'kyc_status,keyword',
          field_value: `${this.props.type},${keyword.toLowerCase()}`,
          page: 1,
          page_size: DEFAULT_PAGE_SIZE,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
      },
    );
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sellers !== this.props.sellers) {
      this.setState({
        ...this.state,
        userList: nextProps.sellers.sellers.sellers,
        total_items: nextProps.sellers.sellers.total_items,
      });
    }
    if (nextProps.type !== this.props.type) {
      this.setState(
        {
          ...this.state,
          filter: {
            field_name: 'kyc_status',
            field_value: nextProps.type,
            page: 1,
            page_size: 20,
          },
        },
        () => {
          this.paginationHandler({ selected: 0 });
        },
      );
    }
  }

  renderFullName(user) {
    const setting = user.user_settings ? JSON.parse(user.user_settings) : {};

    return setting.full_name || user.user_name;
  }

  renderContactMethod(user) {
    const setting = user.user_settings ? JSON.parse(user.user_settings) : {};

    return setting.contact_method
      ? `${SOCIAL_TYPE[setting.contact_method || 0]} - ${setting.contact_method_url || ''}`
      : '';
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Marketplace</a>
            </li>
            <li className="breadcrumb-item active">Users</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">Users</h2>
        <div id="members" className="table-list">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <DebounceInput
                    placeholder="Search"
                    aria-label="Search"
                    minLength={4}
                    debounceTimeout={300}
                    className="form-control search-input search"
                    onChange={(event) => this.searchUser(event.target.value)}
                  />
                  <span className="fas fa-search search-box-icon"></span>
                </form>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  Export
                </button>
                <a href="users/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add member
                </a>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8">
                        <input
                          className="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '5%', minWidth: '50px' }}
                    >
                      USER ID
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      SELLER NAME
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      FULL NAME
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="email"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      EMAIL
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="email"
                      style={{ width: '15%', minWidth: '150px' }}
                    >
                      SOCIAL
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="last_active"
                      style={{ width: '15%', minWidth: '100px' }}
                    >
                      IP ADDRESS
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="last_active"
                      style={{ width: '15%', minWidth: '100px' }}
                    >
                      KYC
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="joined"
                      style={{ width: '19%', minWidth: '200px' }}
                    >
                      JOINED
                    </th>
                    <th className="sort align-middle text-start" scope="col">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {(this.state.userList || []).map((user) => (
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="fs-9 align-middle ps-0 py-3">
                        <div className="form-check mb-0 fs-8">
                          <input className="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                        </div>
                      </td>
                      <td className="user_id align-middle white-space-nowrap">{getUserId(user.user_id)}</td>
                      <td className="customer align-middle white-space-nowrap">
                        <a
                          className="d-flex align-items-center text-body text-hover-1000"
                          href="#!"
                          data-bs-toggle="modal"
                          data-bs-target="#scrollingLong"
                          onClick={() => this.setSelectdSeller(user)}
                        >
                          <div className="avatar avatar-m">
                            <img
                              className="rounded-circle"
                              src={
                                user.selfie_with_id_card ||
                                'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'
                              }
                              alt=""
                            />
                          </div>
                          <div className="mb-0 ms-3">
                            <h6 className="username fw-semibold">{this.renderFullName(user)}</h6>
                            <small>Custom Rank: {user.is_overrided}</small>
                            <br />
                            <small>Rank: {user.rank}</small>
                            <br />
                            <small>Sold products: {user.sold_products}</small>
                            <br />
                            <small>Total rate: {user.total_rate}</small>
                            <br />
                            <small>Rating: {user.rating}</small>
                            <br />
                          </div>
                        </a>
                      </td>
                      <td className="email align-middle white-space-nowrap">{user.full_name}</td>
                      <td className="email align-middle white-space-nowrap">
                        <a className="fw-semibold" href={`mailto:${user.email}`}>
                          {user.email}
                        </a>
                      </td>
                      <td className="email align-middle">
                        {SOCIAL_TYPE[user.messenger] && (
                          <>
                            <span className="fw-semibold">{SOCIAL_TYPE[user.messenger]}:</span>
                            <br />
                          </>
                        )}

                        <a className="fw-semibold" href={`${user.messenger_link}`}>
                          {user.messenger_link}
                        </a>
                      </td>
                      <td className="mobile_number align-middle white-space-nowrap">
                        {user.ip_address} <br />
                        {user.country && <small>({user.country})</small>}
                      </td>
                      <td className="mobile_number align-middle white-space-nowrap">
                        <span
                          className={`badge badge-phoenix fs-10 badge-phoenix-${
                            KYC_STATUSES[user.kyc_status]
                          } d-inline-flex align-items-center`}
                        >
                          {KYC_STATUS_ENUM[user.kyc_status]}
                        </span>
                      </td>
                      <td className="joined align-middle white-space-nowrap text-body-tertiary">
                        {formatDateTime(user.created_at)}
                      </td>
                      <td className="action align-middle white-space-nowrap text-body-tertiary text-start f">
                        {user.kyc_status !== 3 && (
                          <a className="px-2" href={`users/edit/${user.id}`}>
                            Edit
                          </a>
                        )}
                        {user.kyc_status == 3 && (
                          <a className="px-2 text-success" href="#!" onClick={() => this.handleDisable(user, 2)}>
                            Active
                          </a>
                        )}
                        {user.kyc_status == 2 && (
                          <>
                            <a
                              className="px-2 text-danger"
                              href="#!"
                              class="text-danger"
                              aria-hidden="true"
                              data-bs-toggle="modal"
                              data-bs-target="#disputeModal"
                              onClick={() =>
                                this.setState({
                                  user,
                                })
                              }
                            >
                              Block
                            </a>
                          </>
                        )}
                        {user.kyc_status == 0 && (
                          <a className="px-2 text-success" href="#!" onClick={() => this.handleDisable(user, 2)}>
                            Approve
                          </a>
                        )}
                        {user.kyc_status == 0 && (
                          <a className="px-2 text-danger" href="#!" onClick={() => this.handleDisable(user, 3)}>
                            Decline
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center justify-content-between py-2 pe-0 fs-9 mt-3">
              <div class="col-auto d-flex">
                <p class="mb-0 d-none d-sm-block me-3 fw-semibold text-body">
                  View {this.state.filter.page * DEFAULT_PAGE_SIZE} of {this.state.total_items}
                </p>
              </div>
              <div class="col-auto d-flex">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  activeClassName={'active'}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  pageLinkClassName={'page'}
                  breakLinkClassName={'page'}
                  previousClassName={'page-link'}
                  nextClassName={'page-link'}
                  initialPage={this.state.filter.page - 1}
                  pageCount={Math.ceil(this.state.total_items / 20)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.paginationHandler}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="scrollingLong"
          tabindex="-1"
          aria-labelledby="scrollingLongModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="scrollingLongModalLabel">
                  {this.state.seller.full_name}
                </h5>
                <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                  <span className="fas fa-times fs-9"></span>
                </button>
              </div>
              <div className="modal-body">
                <img src={this.state.seller?.selfie_with_id_card} alt="" style={{ width: '100%' }} />
                <img src={this.state.seller?.id_card_front} alt="" style={{ width: '100%' }} />
                <img src={this.state.seller?.id_card_back} alt="" style={{ width: '100%' }} />
              </div>
              <div className="modal-footer">
                <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <SuspendModal handleOk={this.handleOk} />
      </>
    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  sellers: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getSellerLists: PropTypes.func.isRequired,
  unapproveSeller: PropTypes.func.isRequired,
  approveSeller: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sellers: state.sellers,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, { getSellerLists, unapproveSeller, approveSeller, blockUser })(Users);
