import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getRefundTransactionsLists, updateRefund, deleteRefund } from '../../../actions/transaction';
import { getSellerLists } from '../../../actions/seller';

import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import advanceAjaxTableInit from '../../../utils/advanceAjaxTableInit';
import { formatDateTime, convertCurrency } from '../../../utils/helpers';

const filterKey = ['Pending', 'Completed'];

const statuses = [
  'UnKnown',
  'Pending',
  'Awaiting Payment',
  'Paid',
  'Completed',
  'Refund',
  'Fail',
  'Delivered',
  'Suspended',
  'On Hold',
  'Partial Refund',
];

const statusesColor = [
  'default',
  'warning',
  'warning',
  'success',
  'success',
  'warning',
  'danger',
  'success',
  'danger',
  'success',
  'success',
];

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
        type: 'Refund',
      },
      transactionList: [],
      total_items: 0,
      activeStatus: '',
      sellers: [],
    };
    this.setActiveStatus = this.setActiveStatus.bind(this);
  }

  updateStatus = async (record, status) => {
    await this.props.updateRefund(record._id, {
      status,
    });
    this.paginationHandler({ selected: this.state.filter.page - 1 });
  };

  makeAPICall = (searchValue) => {
    if (!searchValue) {
      return;
    }
    this.props.getSellerLists({
      field_name: 'kyc_status,keyword',
      field_value: `2,${searchValue}`,
      page: 1,
      page_size: 20,
    });
  };
  debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  onInput = this.debounce(this.makeAPICall, 500);

  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getRefundTransactionsLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  generateTable = () => {
    const options = {
      page: 12,
      pagination: {
        item: "<li><button class='page' type='button'></button></li>",
      },
      searchColumns: ['seller_name', '_id', 'item_name'],
    };

    window.tableList = advanceAjaxTableInit(options);
  };
  filterTransaction = (seller_id) => {
    const items = this.state.transactionList.filter((el) => el.seller_id == seller_id);
    this.generateTable(items);
  };
  async handleRemove(id) {
    const confirm = window.confirm(`Are you sure delete this record?`);
    if (confirm) {
      window.tableList.remove('_id', id);
      await this.props.deleteRefund(id);
    }
    return false;
  }
  setActiveStatus(status) {
    this.setState(
      {
        activeStatus: status,
        filter: {
          status,
          page: 1,
          page_size: DEFAULT_PAGE_SIZE,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
        window.choices.clearStore();
      },
    );
  }
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
  }
  componentDidUpdate() {
    const that = this;
    const element = document.querySelector('#seller');
    if (element && !window.choices) {
      window.choices = new window.Choices(element, {
        silent: true,
        searchEnabled: true,
        searchChoices: false,
        placeholder: true,
        placeholderValue: 'Search Seller',
        searchPlaceholderValue: 'Search Seller...',
        choices: [],
      });
      window.choices.clearChoices();
      element.addEventListener(
        'search',
        function (event) {
          that.onInput(event.detail.value);
        },
        false,
      );
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.transactions !== this.props.transactions) {
      this.setState(
        {
          ...this.state,
          transactionList: nextProps.transactions.transactions.data,
        },
        () => {
          this.generateTable(nextProps.transactions.transactions.data);
        },
      );
    }

    if (nextProps.sellers !== this.props.sellers) {
      this.setState({
        ...this.state,
        sellers: nextProps.sellers.sellers.sellers,
      });
      if (window.choices) {
        window.choices.setChoices(
          (nextProps.sellers.sellers.sellers || []).map((el) => {
            return { value: el.user_id, label: el.user_name };
          }),
          'value',
          'label',
          true,
        );
      }
    }
  }
  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">Transactions</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">Transactions</h2>
        <div
          id="advanceTable"
          data-list='{"valueNames":[],"page":12,"pagination":{"innerWindow":2,"left":1,"right":1}}'
        >
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto d-flex flex-row">
              <div className="search-box me-1">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input
                    className="form-control search-input search fuzzy-search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <span className="fas fa-search search-box-icon"></span>
                </form>
              </div>
              <select
                className="form-select1 ms-3"
                id="organizerSingle"
                data-choices="data-choices"
                data-options='{"removeItemButton":false,"placeholder":true}'
                onChange={(e) => this.setActiveStatus(e.target.value)}
              >
                <option selected={this.state.activeStatus == ''} value="">
                  All
                </option>
                {filterKey.map((value) => (
                  <option value={value} selected={this.state.activeStatus == value}>
                    {value}
                  </option>
                ))}
              </select>
              <div className="ms-3">
                <select
                  id="seller"
                  className="form-select"
                  onChange={(e) => this.filterTransaction(e.target.value)}
                ></select>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>Export
                </button>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="order"
                      style={{ width: '30%', maxWidth: '300px' }}
                    >
                      ORDER
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="status"
                      style={{ width: '5%', minWidth: '50px' }}
                    >
                      PAYMENT METHOD
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="status"
                      style={{ width: '5%', minWidth: '50px' }}
                    >
                      REFUND STATUS
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="status"
                      style={{ width: '5%', minWidth: '50px' }}
                    >
                      ORDER STATUS
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="seller_name"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      SELLER
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="buyer_name"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      BUYER
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="price"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      TOTAL PRICE
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="fee"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      REFUNDED TO BUYER
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {this.state.transactionList.map((method) => {
                    const order = method?.order[0] || {};
                    return (
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="customer align-middle">
                          <div className="text-body text-hover-1000">
                            <div className="mb-0">
                              <strong className="">REQUEST ID</strong>: <span className="_id">{method._id}</span>
                              <br />
                              <strong>Request Date</strong>: {formatDateTime(method.created_time * 1000)}
                              <br />
                              <strong>Order Date</strong>: {formatDateTime(order.created_time * 1000)}
                              <br />
                              <strong className="">Order ID</strong>: <span className="id">{order._id}</span>
                              <br />
                              <strong className="">Transaction ID</strong>:{' '}
                              <span className="merchant_trade_no">{order.merchant_trade_no}</span>
                              <br />
                              <strong>Order Title</strong>: <span className="item_name">{order.item_name}</span>
                              <br />
                            </div>
                          </div>
                          <a href={`/admin/marketplace/chat/${method.order[0]._id}`}>View Chat</a>
                        </td>
                        <td className="seller_name align-middle white-space-nowrap">{order?.pay_platform}</td>
                        <td className="status align-middle white-space-nowrap">
                          <span
                            className={`badge badge-phoenix fs-10 badge-phoenix-${
                              method.status == 'Pending' ? 'warning' : 'success'
                            } d-inline-flex align-items-center`}
                          >
                            {method.status}
                          </span>
                        </td>
                        <td className="status align-middle white-space-nowrap">
                          <span
                            className={`badge badge-phoenix fs-10 badge-phoenix-${
                              statusesColor[order.status]
                            } d-inline-flex align-items-center`}
                          >
                            {statuses[order?.status]}
                          </span>
                        </td>
                        <td className="seller_name align-middle white-space-nowrap">
                          <h6 className="mb-0 fw-semibold">
                            <span className="">{order?.seller_info?.user_name}</span>
                            <small> ({order?.seller_info?.user_rank})</small>
                          </h6>
                          <small> ({order?.seller_id})</small>
                        </td>
                        <td className="buyer_name align-middle white-space-nowrap">
                          <h6 className="mb-0 fw-semibold">{order?.buyer_info?.user_name}</h6>
                          <div> {order?.buyer_info?.email}</div>
                          <small> ({order?.buyer_id})</small>
                        </td>
                        <td className="price align-middle white-space-nowrap">
                          {' '}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertCurrency(order?.price, true, this.props.settings?.data?.sgd),
                            }}
                          ></span>
                        </td>
                        <td className="fee_charge_seller align-middle white-space-nowrap">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertCurrency(
                                order?.status == 5 ? order?.price : order?.refund,
                                true,
                                this.props.settings?.data?.sgd,
                              ),
                            }}
                          ></span>
                        </td>
                        <td className="joined align-middle white-space-nowrap text-body-tertiary text-end">
                          <div className="btn-reveal-trigger position-static">
                            <button
                              className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                              type="button"
                              data-bs-toggle="dropdown"
                              data-boundary="window"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-bs-reference="parent"
                            >
                              <span className="fas fa-ellipsis-h fs-10"></span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end py-2">
                              {/* <Link className="dropdown-item" to={`/admin/marketplace/refund/${method.order[0]._id}`}>
                                VIEW
                              </Link> */}
                              <a
                                className="dropdown-item text-danger"
                                href="#!"
                                onClick={() => this.updateStatus(method, 'Completed')}
                              >
                                REFUND
                              </a>
                              <a
                                className="dropdown-item text-danger"
                                href="#!"
                                onClick={() => this.handleRemove(method._id)}
                              >
                                REMOVE
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center justify-content-between py-2 pe-0 fs-9">
              <div className="col-auto d-flex">
                <p className="mb-0 d-none d-sm-block me-3 fw-semibold text-body" data-list-info="data-list-info"></p>
                <a className="fw-semibold" href="#!" data-list-view="*">
                  View all
                  <span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
                <a className="fw-semibold d-none" href="#!" data-list-view="less">
                  View Less
                  <span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
              </div>
              <div className="col-auto d-flex">
                <button className="page-link" data-list-pagination="prev">
                  <span className="fas fa-chevron-left"></span>
                </button>
                <ul className="mb-0 pagination"></ul>
                <button className="page-link pe-0" data-list-pagination="next">
                  <span className="fas fa-chevron-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Transactions.propTypes = {
  auth: PropTypes.object.isRequired,
  transactions: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getRefundTransactionsLists: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  transactions: state.transactions,
  sellers: state.sellers,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getRefundTransactionsLists,
  getSellerLists,
  updateRefund,
  deleteRefund,
})(Transactions);
