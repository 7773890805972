import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import { DELETE_PRODUCT, GET_ERRORS, GET_PRODUCT_LIST, CREATE_PRODUCT, UPDATE_PRODUCT } from './types';

// Get List of Users
export const getProductLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/product/v1/auth/admin/product?${params}`)
      .then((res) => {
        dispatch({
          type: GET_PRODUCT_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deleteProduct = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/product/v1/auth/admin/product/${id}`, {})
      .then((res) => {
        dispatch({
          type: DELETE_PRODUCT,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deleteBulkProduct = (ids) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/product/v1/auth/admin/product/delete`, { ids })
      .then((res) => {
        dispatch({
          type: DELETE_PRODUCT,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createProduct = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post('/product/v1/auth/admin/product', values)
      .then((res) => {
        dispatch({
          type: CREATE_PRODUCT,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateProduct = (id, data) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/product/v1/auth/admin/product/${id}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_PRODUCT,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const boostProduct = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/product/v1/auth/admin/product/${id}/boost`, {})
      .then((res) => {
        dispatch({
          type: UPDATE_PRODUCT,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
