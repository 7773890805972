import { GET_MARKETING_BANNER_LIST, DELETE_MARKETING_BANNER, CREATE_MARKETING_BANNER } from '../actions/types';

const initialState = {
  marketings: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MARKETING_BANNER_LIST:
      return {
        ...state,
        marketings: action.payload,
      };
    case CREATE_MARKETING_BANNER:
    case DELETE_MARKETING_BANNER:
      return {
        ...state,
      };
    default:
      return state;
  }
}
